import React from 'react'
import { Box, Text } from 'theme-ui'

const Copyright = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Text sx={{ variant: 'styles.dl' }}>
        Copyright © {new Date().getFullYear()} Independent Electronic Forum
      </Text>
    </Box>
  )
}

export default Copyright
