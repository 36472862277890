import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Box } from 'theme-ui'
import theme from '../../theme'

const activeStyle = {
  color: '#AAAAAA',
}

const NavLink = ({ to, children }) => {
  return (
    <Box px={4}>
      <LinkStyled to={to} activeStyle={activeStyle}>
        <Box
          sx={{
            ':hover': {
              transition: 'all .3s ease-out',
              boxShadow: `0 -1px 0 0 ${theme.link.secondary.color} inset`,
              width: 'fit-content',
            },
          }}
        >
          {children}
        </Box>
      </LinkStyled>
    </Box>
  )
}

const LinkStyled = styled(Link)`
  color: ${theme.link.secondary.color};
`

NavLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
}

export default NavLink
