import React from 'react'
import { Box, Flex } from 'theme-ui'
import Copyright from './copyright'
import theme from '../theme'

const Footer = class extends React.Component {
  render() {
    return (
      <footer>
        <Box bg={theme.footer.backgroundColor} py={5}>
          <Flex sx={{ justifyContent: 'center', flexDirection: 'column' }}>
            <Copyright />
          </Flex>
        </Box>
      </footer>
    )
  }
}

export default Footer
