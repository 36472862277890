import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`


  *, *:before, *:after {
    box-sizing: border-box;
  }

  html {
    background: black;
  }

  main {
    background: white;
  }

  html,
    body,
    body > div:first-child,
    main {
      height: 100%;
    }

    html, body, #___gatsby, #gatsby-focus-wrapper {
      height: 100%;
    }

    div[role="group"][tabindex] {
        height: 100%;
    }

    html,
    body {
      margin: 0;
      height: 100%;
      max-width: 100%;
      box-sizing: border-box;
      font-family: "ProximaNova", Helvetica, sans-serif;
    }

    .ReactModal__Body--open,
    .ReactModal__Html--open {
      overflow: hidden;
    }
  
    * {
      margin: 0;
      padding: 0;
      box-sizing: inherit;
    }

    a {
      text-decoration: none;
    }

    input[type=checkbox]:checked:after {
      background-color: #000;
    }

    /* Mailchimp form */
    #mc_embed_signup .mc-field-group.input-group input{
      margin-right: 4px;
    }

    #mc_embed_signup .mc-field-group.input-group input:checked{
      background-color: black;
    }

    #mc_embed_signup .button{
      background-color: white;
      color: black;
      border: 1px solid black;
    }
`
