import React from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import PropTypes from 'prop-types'
import { withPrefix } from 'gatsby'
import Footer from './Footer'
import useSiteMetadata from './SiteMetadata'
import Header from './header'
import Nav from './nav'
import { GlobalStyle } from '../theme/global-style'
import theme from '../theme'

const TemplateWrapper = ({ children }) => {
  const { title, description, image } = useSiteMetadata()
  return (
    <div
      style={{
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Helmet>
          <html lang="en" />
          <title> {title} </title>
          <meta name="description" content={description} />
          <meta name="image" content={image} />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${withPrefix('/')}img/logo.svg`}
          />
          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix('/')}img/logo.svg`}
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix('/')}img/logo.svg`}
            sizes="16x16"
          />
          <link
            rel="mask-icon"
            href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
            color="#ff4400"
          />
          <meta name="theme-color" content="#fff" />
          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={title} />
          <meta property="og:image" content={image} />
          <meta property="og:url" content="/" />
          <meta
            property="og:image"
            content={`${withPrefix('/')}img/og-image.jpg`}
          />
        </Helmet>

        <Header />
        <Nav />
        <main style={{ flexGrow: 1 }}>{children}</main>
        <Footer />
      </ThemeProvider>
    </div>
  )
}

TemplateWrapper.propTypes = {
  children: PropTypes.node,
}

export default TemplateWrapper
