import React from 'react'
import { Flex } from 'theme-ui'
import styled from 'styled-components'
import NavLink from '../nav-link'
import theme from '../../theme'

const Menu = () => (
  <Flex sx={{ justifyContent: 'center' }} py={3}>
    {/* <NavLink to="/what-why">WHAT/WHY</NavLink>
    <VerticalBar /> */}
    <NavLink to="/about">ABOUT</NavLink>
    <VerticalBar />
    <NavLink to="/report">REPORT</NavLink>
  </Flex>
)

const VerticalBar = () => <Span>&#124;</Span>

const Span = styled.span`
  color: ${theme.nav.navSeparator};
`

export default Menu
