import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Box, Flex } from 'theme-ui'
import logo from '../../img/logo.svg'
import theme from '../../theme'

const Header = () => {
  return (
    <Flex
      sx={{ justifyContent: 'space-around' }}
      bg={theme.header.backgroundColor}
    >
      <Box mb={{ s: 3, m: 4 }} mt={{ s: 4, m: 5 }}>
        <Link to="/" title="Logo">
          <Img src={logo} alt="IEF logo" style={{ width: '14em' }} />
        </Link>
      </Box>
    </Flex>
  )
}

const Img = styled.img`
  display: block;
`

export default Header
