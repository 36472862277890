import React from 'react'
import styled from 'styled-components'
import Menu from '../menu'
import theme from '../../theme'

const Nav = () => {
  return (
    <NavBar role="navigation">
      <Menu />
    </NavBar>
  )
}

const NavBar = styled.nav`
  background-color: ${theme.nav.backgroundColor};
`

export default Nav
